import {IShift} from '@shared/repositories/sdweb.repository';

export function getShiftLabel(shift: IShift): string {
	const prepareTime = str => str.split(':').slice(0, 2).join(':');

	const startTime = prepareTime(shift.start);
	const endTime = prepareTime(shift.end);

	return `${shift.name} (${startTime} - ${(endTime)})`;
}
