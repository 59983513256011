import { IShift } from '@shared/repositories/sdweb.repository';
import { ex } from '@fullcalendar/core/internal-common';

export function checkShiftByCurrentTime(shift: IShift): boolean {
	const start = shift.start.split(':');
	const end = shift.end.split(':');
	const current = new Date(Date.now());

	const startDurationSec = Number(start[0]) * 3600 + Number(start[1]) * 60 + Number(start[2]);
	const endDurationSec = Number(end[0]) * 3600 + Number(end[1]) * 60 + Number(end[2]);
	const currentDurationSec = current.getHours() * 3600 + current.getMinutes() * 60 + current.getSeconds();

	return startDurationSec <= currentDurationSec && endDurationSec >= currentDurationSec
		|| (
			startDurationSec > endDurationSec
			&& (
				startDurationSec <= currentDurationSec && endDurationSec <= currentDurationSec
				|| startDurationSec >= currentDurationSec && endDurationSec >= currentDurationSec
			)
		);
}

export function definitionShiftByCurrentTime(shifts: IShift[]): IShift | null {
	for (let i = 0; i < shifts.length; i++) {
		const shift = shifts[i];

		if (checkShiftByCurrentTime(shift)) {
			return shift
		}
	}

	return null
}
